import { useState } from "react"
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material"
import useFormacion from "../../../../../hooks/useFormacion"
import css from "./DatosAsignaturas.module.css"

const DatosAsignaturas = () => {

  const { asistencias } = useFormacion()
  const [isExpanded, setExpanded] = useState(true);

  const handleChange = (_event, isExpanded) => {
    setExpanded(isExpanded);
  }

  const asignaturas = asistencias.reduce((acc, asistencia) => {
    const nombre = `${asistencia.asignatura} (${asistencia.nivel})`
    acc[nombre] = (acc[nombre] || 0) + 1
    return acc
  }, {})

  return (
    <Accordion expanded={isExpanded} onChange={handleChange}>
      <AccordionSummary
        expandIcon={<i className="material-icons">expand_more</i>}
      >
        <div className={css.label}>
          <Typography className={css.title} variant="h2">
            Datos asignaturas
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails className={css.content}>
        {Object.keys(asignaturas).map(asignatura => (
         <Typography key={asignatura}>
            {asignaturas[asignatura]} horas de{" "} <strong>{asignatura}</strong>
          </Typography>
        ))}
      </AccordionDetails>
    </Accordion>
  )
}

export default DatosAsignaturas
