import { useCallback, useEffect, useState } from "react"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { dateToString, dateToWeekday, getToday } from "../../utils/date"
import FechaSelector from "../../components/form/FechaSelector/FechaSelector"
import { getAsistenciaLeadList, getAsistenciaList, getAsistenciaTotals, updateAsistencia, updateAsistenciaLead } from "../../utils/api/asistencias"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useNotification } from "../../context/NotificationManager/NotificationManager"
import { getHorario } from "../../utils/api/modelosHorario"
import { getAuth } from "../../utils/auth"
import PageLoading, { LoadingSpinner } from "../../components/PageLoading/PageLoading"
import Tabs from "../../components/Tabs/Tabs"
import { getTotales } from "../../utils/model/asistencias"
import { getMostRelevantHour } from "../../utils/model/horario"
import ClaseRow from "./components/ClaseRow/ClaseRow"
import PruebaRow from "./components/PruebaRow/PruebaRow"
import useResponsive from "../../hooks/useResponsive"
import css from './Planificacion.module.css'
import FormacionRow from "./components/FormacionRow/FormacionRow"
import { getAsistenciaFormacionList } from "../../utils/api/asistenciasFormacion"

const TabContent = ({ 
  pruebas,
  asistencias, 
  formaciones,
  centro,
  isLoading,
  onAsistenciaChange,
  onPruebaChange,
  onAsistenciaProfesorChange,
  onPruebaProfesorChange,
  onObservacionesAsistenciaChange,
  onObservacionesPruebaChange,
})=> {

  const [open, setOpen] = useState('')

  const { isScreenBiggerThan } = useResponsive()

  if (isLoading) return <LoadingSpinner />

  return (
    <TableContainer className={css.tabContent}>
      <Table stickyHeader className={css.table} size='small'>
        <TableHead>
        <TableRow>
          <TableCell className={css.cell}>
            Alumno
          </TableCell>
          <TableCell 
            className={css.cell} 
            data-visibility={isScreenBiggerThan('sm') ? 'visible' : 'invisible'}
          >
            Asignatura
          </TableCell>
          <TableCell 
            className={css.cell} 
            data-visibility={isScreenBiggerThan('md') ? 'visible' : 'invisible'}
          >
            Profesor
          </TableCell>
          <TableCell 
            className={css.cell} 
            data-visibility={isScreenBiggerThan('lg') ? 'visible' : 'invisible'}
          >
            Tipo de clase
          </TableCell>
          <TableCell className={css.cell} align="center">
            Asistencia
          </TableCell>
          <TableCell className={css.cell} />
        </TableRow>
        </TableHead>
        <TableBody>
          {formaciones
            .sort((p1, p2)=> p1.formacion.localeCompare(p2.formacion))
            .map(asistencia=> (
              <FormacionRow 
                key={asistencia.formacionId}
                asistencia={asistencia}
                isOpen={open === asistencia.formacionId}
                onOpen={()=> setOpen(open === asistencia.formacionId ? '' : asistencia.formacionId)}
              />
            ))}
          {pruebas
            .sort((p1, p2)=> p1.feedbackNombre.localeCompare(p2.feedbackNombre))
            .map(asistencia=> (
              <PruebaRow 
                key={asistencia.feedbackId}
                asistencia={asistencia}
                centro={centro}
                isOpen={open === asistencia.feedbackId}
                onOpen={()=> setOpen(open === asistencia.feedbackId ? '' : asistencia.feedbackId)}
                onChange={onPruebaChange}
                onProfesorChange={onPruebaProfesorChange}
                onObservacionesChange={onObservacionesPruebaChange}
              />
            ))}
          {asistencias
            .sort((a1, a2)=> a1.alumno.localeCompare(a2.alumno))
            .map(asistencia=> (
              <ClaseRow 
                key={asistencia.id}
                asistencia={asistencia}
                centro={centro}
                isOpen={open === asistencia.id}
                onOpen={() => setOpen(open === asistencia.id ? '' : asistencia.id)}
                onChange={onAsistenciaChange}
                onProfesorChange={onAsistenciaProfesorChange}
                onObservacionesChange={onObservacionesAsistenciaChange}
              />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const TabLabel = ({ hora, total })=> {
  if (!total) return hora
  const { alumnos, detalles } = getTotales({ total })
  return (
    <div className={css.tabLabel}>
      <div>{hora} ({alumnos})</div>
      <div className={css.tabLabelDetalles}>
        {detalles.map(detalle=> (
          <span className={css.tabLabelElement} data-tipo={detalle.type} key={detalle.type}>
            {detalle.value}
          </span>
        ))}
      </div>
    </div>
  )
}

const Planificacion = ()=> {

  const notification = useNotification()
  const queryClient = useQueryClient()
  const userData = getAuth()

  const [fecha, setFecha] = useState(getToday())
  const [hora, setHora] = useState(null)

  const { 
    isLoading: isHorarioLoading, 
    isError: isHorarioError, 
    isSuccess: isHorarioSuccess,
    data: horario=[] 
  } = useQuery({
    queryKey: ['planificacion', 'modelo-horario', userData.centro, dateToString(fecha)], 
    queryFn: ()=> getHorario({ fecha: dateToString(fecha) })
      .then(datos=> datos.horas)
  })

  const { data: totals={} } = useQuery({
    queryKey: [ 'planificacion', 'asistencia', 'list', 'totals', dateToString(fecha)], 
    enabled: fecha !== null,
    queryFn: ()=> getAsistenciaTotals({ fecha: dateToString(fecha) })
      .then(datos=> datos)
      .catch(()=> null)
  })

  const { isFetching: isAsistenciasLoading, data: asistenciaList=[] } = useQuery({
    queryKey: [ 'planificacion', 'asistencia', 'list', dateToString(fecha), hora], 
    enabled: fecha !== null && hora !== null,
    queryFn: ()=> getAsistenciaList({ fecha: dateToString(fecha), hora })
      .then(datos=> datos)
      .catch(err=> {
        notification.error({ title: 'Error al recuperar las asistencias', content: err })
        return []
      })
  })

  const { isFetching: isPruebasLoading, data: pruebaList=[] } = useQuery({
    queryKey: [ 'planificacion', 'asistencia', 'list', 'feedback', dateToString(fecha), hora],
    enabled: fecha !== null && hora !== null,
    queryFn: ()=> getAsistenciaLeadList({ fecha: dateToString(fecha), hora })
      .then(datos=> datos)
      .catch(err=> {
        notification.error({ title: 'Error al recuperar las pruebas', content: err })
        return []
      })
  })

  const { isFetching: isFormacionesLoading, data: formacionList=[] } = useQuery({
    queryKey: [ 'planificacion', 'asistencia-formacion', 'list', dateToString(fecha), hora],
    enabled: fecha !== null && hora !== null,
    queryFn: ()=> getAsistenciaFormacionList({ fecha: dateToString(fecha), hora })
      .then(datos=> datos)
      .catch(err=> {
        notification.error({ title: 'Error al recuperar las formaciones', content: err })
        return []
      })
  })

  const { mutate: modificarAsistencia } = useMutation({
    mutationFn: updateAsistencia,
    onSuccess: (_result, { fecha, multiple })=> {
      // Invalidamos el total de asistencias y las asistencias de la pestaña actual
      queryClient.invalidateQueries({
        queryKey: [ 'planificacion', 'asistencia', 'list' ],
        type: 'active',
      })
      // Borramos los datos de cache de las otras horas si nos llega el "mutliple" (que puede afectar a varias horas)
      if (multiple) {
        queryClient.removeQueries({
          queryKey: [ 'planificacion', 'asistencia', 'list', fecha ],
          type: 'inactive'
        })
      }
    },
    onError: err=> {
      notification.error({ title: 'Error al actualizar la asistencia', content: err })
    }
  })

  const { mutate: modificarPrueba } = useMutation({
    mutationFn: updateAsistenciaLead,
    onSuccess: ()=> {
      queryClient.invalidateQueries({
        queryKey: [ 'planificacion', 'asistencia', 'feedback', 'list' ],
        type: 'active',
      })
    },
    onError: err=> {
      notification.error({ title: 'Error al actualizar la prueba', content: err })
    }
  })

  const horaActual = getMostRelevantHour(horario)

  useEffect(()=> {
    if (!hora) setHora(horaActual)
  }, [hora, horaActual])

  useEffect(()=> {
    setHora(null)
  }, [fecha])

  const handleAsistenciaClick = (asistenciaAlumno, asistenciaId)=> {
    const asistenciaModificar = asistenciaList.find(asistencia=> asistencia.id === asistenciaId)
    // Enviamos información extra para luego poder invalidar la cache correctamente
    modificarAsistencia({
      id: asistenciaId,
      asistencia: asistenciaAlumno,
      fecha: dateToString(asistenciaModificar.fecha),
      multiple: true,
    })
  }

  const handlePruebaClick = (asistenciaAlumno, feedbackId)=> {
    const pruebaModificar = pruebaList.find(asistencia=> asistencia.feedbackId === feedbackId)
    modificarPrueba({
      id: feedbackId,
      asistencia: asistenciaAlumno,
      fecha: dateToString(pruebaModificar.fecha),
      hora: pruebaModificar.hora,
    })
  }

  const handleProfesorChange = (profesorId, asistenciaId)=> {
    const asistenciaModificar = asistenciaList.find(asistencia=> asistencia.id === asistenciaId)
    // Enviamos información extra para luego poder invalidar la cache correctamente
    modificarAsistencia({
      id: asistenciaId,
      profesor: profesorId,
      fecha: dateToString(asistenciaModificar.fecha),
      multiple: true,
    })
  }

  const handlePruebaProfesorChange = (profesorId, feedbackId)=> {
    const pruebaModificar = pruebaList.find(asistencia=> asistencia.feedbackId === feedbackId)
    modificarPrueba({
      id: feedbackId,
      profesor: profesorId,
      fecha: dateToString(pruebaModificar.fecha),
      hora: pruebaModificar.hora,
    })
  }

  const handleAsistenciaObservacionesChange = useCallback((observaciones, asistenciaId)=> {
    const asistenciaModificar = asistenciaList.find(asistencia=> asistencia.id === asistenciaId)
    // Enviamos información extra para luego poder invalidar la cache correctamente
    modificarAsistencia({
      id: asistenciaId,
      observaciones,
      fecha: dateToString(asistenciaModificar.fecha),
    })
  }, [asistenciaList, modificarAsistencia])

  const handlePruebaObservacionesChange = useCallback((observaciones, feedbackId)=> {
    const pruebaModificar = pruebaList.find(asistencia=> asistencia.feedbackId === feedbackId)
    modificarPrueba({
      id: feedbackId,
      observaciones,
      fecha: dateToString(pruebaModificar.fecha),
      hora: pruebaModificar.hora,
    })
  }, [pruebaList, modificarPrueba])


  return (
    <PageLoading isLoading={isHorarioLoading}>
      <div className={css.header}>
        <span>
          Clases del día {dateToString(fecha)} ({dateToWeekday(fecha)})
        </span>
        <FechaSelector
          className={css.fechaSelector}
          label='Cambiar fecha'
          value={null}
          onChange={setFecha}
        />
      </div>
      {isHorarioError && (
        <div className={css.error}>
          <p className={css.errorMessage}>
            No hay horario configurado para este día
          </p>
        </div> 
      )}
      {isHorarioSuccess && (
        <Tabs
          className={css.tabs}
          tabs={horario.map(hora=> ({
            name: hora,
            label: <TabLabel hora={hora} total={totals[hora]} />,
            content: (
              <TabContent 
                asistencias={asistenciaList}
                pruebas={pruebaList}
                formaciones={formacionList}
                centro={userData.centro}
                isLoading={isAsistenciasLoading && isPruebasLoading && isFormacionesLoading}  
                onAsistenciaChange={handleAsistenciaClick}
                onPruebaChange={handlePruebaClick}
                onAsistenciaProfesorChange={handleProfesorChange}
                onPruebaProfesorChange={handlePruebaProfesorChange}
                onObservacionesAsistenciaChange={handleAsistenciaObservacionesChange}
                onObservacionesPruebaChange={handlePruebaObservacionesChange}
              />
            ),
          }))}
          activeTab={hora}
          onChange={setHora}
        />
      )}
    </PageLoading>
  )


}

export default Planificacion