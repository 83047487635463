import { Card, CardMedia, Typography } from "@mui/material"
import PageLoading from "../../../components/PageLoading/PageLoading"
import DatosAsignaturas from "./components/DatosAsignaturas/DatosAsignaturas"
import FormacionHeader from "../../../components/FormacionHeader/FormacionHeader"
import user from "./profile.jpg"
import css from "./Ficha.module.css"
import useFormacion from "../../../hooks/useFormacion"

const Ficha = () => {

  const { formacion, isLoading } = useFormacion()

  return (
    <PageLoading isLoading={isLoading}>
      <div className={css.main}>
        <FormacionHeader label="Ficha" />
        <div className={css.formacion}>
          <Card variant="outlined" className={css.summary}>
            <CardMedia
              className={css.image}
              component="img"
              image={user}
              alt={formacion.nombre}
            />
            <div className={css.content}>
              <Typography variant="h6" component="h2">
                {formacion.nombre}
              </Typography>
              <div className={css.info}>
                <Typography className={css.email} variant="body2" component="p">
                  <i className="material-icons">email</i>
                  {formacion.email}
                </Typography>
                <Typography className={css.academy} variant="body2" component="p">
                  <i className="material-icons">school</i>
                  Profesor en formación de{" "}
                  <strong>{formacion.centro}</strong>
                </Typography>
                {formacion.formacion_completada ? (
                  <Typography className={css.complete} variant="body2" component="p">
                    <i className="material-icons">check</i>
                    Formación completada ({formacion.asistencias?.length} horas)
                  </Typography>
                ) : (
                  <Typography className={css.ongoing} variant="body2" component="p">
                    <i className="material-icons">event_available</i>
                    Formación en curso ({formacion.asistencias?.length} horas)
                  </Typography>
                )}
              </div>
            </div>
          </Card>
          <div className={css.ficha}>
            <div className={css.accordion}>
              <DatosAsignaturas />
            </div>
          </div>
        </div>
      </div>
    </PageLoading>
)
}
export default Ficha
